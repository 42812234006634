import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components/VBtn'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { de, en } from 'vuetify/locale'
import defaults from './defaults'
import { icons } from './icons'
import theme from './theme'
import { getUserLocale } from '@/utils'

// Styles
import '@core/scss/template/libs/vuetify/index.scss'
import 'vuetify/styles'

const userLanguage = navigator.language ? getUserLocale() : 'en'

export default createVuetify({
  aliases: {
    IconBtn: VBtn,
  },
  locale: {
    locale: userLanguage,
    fallbackLocale: 'en',
    messages: { en, de },
  },
  defaults,
  icons,
  theme,
})
