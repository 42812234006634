/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardBroadcastMessage } from '../models/DashboardBroadcastMessage';
import type { DashboardLocation } from '../models/DashboardLocation';
import type { DashboardLocationConfiguration } from '../models/DashboardLocationConfiguration';
import type { DashboardLocationRequest } from '../models/DashboardLocationRequest';
import type { DashboardSession } from '../models/DashboardSession';
import type { DashboardSessionConfiguration } from '../models/DashboardSessionConfiguration';
import type { DashboardSessionRequest } from '../models/DashboardSessionRequest';
import type { DashboardSessionResult } from '../models/DashboardSessionResult';
import type { DashboardSessionResultRequest } from '../models/DashboardSessionResultRequest';
import type { DashboardSessionUpdate } from '../models/DashboardSessionUpdate';
import type { DashboardSessionUpdateRequest } from '../models/DashboardSessionUpdateRequest';
import type { DashboardWorkAssignment } from '../models/DashboardWorkAssignment';
import type { DashboardWorkAssignmentRequest } from '../models/DashboardWorkAssignmentRequest';
import type { DashboardWorker } from '../models/DashboardWorker';
import type { DashboardWorkerConfiguration } from '../models/DashboardWorkerConfiguration';
import type { DashboardWorkerRequest } from '../models/DashboardWorkerRequest';
import type { DashboardWorkerRole } from '../models/DashboardWorkerRole';
import type { DashboardWorkerRoleRequest } from '../models/DashboardWorkerRoleRequest';
import type { PaginatedDashboardLocationList } from '../models/PaginatedDashboardLocationList';
import type { PaginatedDashboardSessionList } from '../models/PaginatedDashboardSessionList';
import type { PaginatedDashboardWorkAssignmentList } from '../models/PaginatedDashboardWorkAssignmentList';
import type { PaginatedDashboardWorkerList } from '../models/PaginatedDashboardWorkerList';
import type { PaginatedDashboardWorkerRoleList } from '../models/PaginatedDashboardWorkerRoleList';
import type { PatchedDashboardLocationConfigurationRequest } from '../models/PatchedDashboardLocationConfigurationRequest';
import type { PatchedDashboardLocationRequest } from '../models/PatchedDashboardLocationRequest';
import type { PatchedDashboardSessionConfigurationRequest } from '../models/PatchedDashboardSessionConfigurationRequest';
import type { PatchedDashboardSessionUpdateRequest } from '../models/PatchedDashboardSessionUpdateRequest';
import type { PatchedDashboardWorkAssignmentRequest } from '../models/PatchedDashboardWorkAssignmentRequest';
import type { PatchedDashboardWorkerConfigurationRequest } from '../models/PatchedDashboardWorkerConfigurationRequest';
import type { PatchedDashboardWorkerRequest } from '../models/PatchedDashboardWorkerRequest';
import type { PatchedDashboardWorkerRoleRequest } from '../models/PatchedDashboardWorkerRoleRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FieldServiceService {

    /**
     * Returns list of `BroadcastMessage` records which are currently active.
     * Records with `target=app` would be excluded.
     * If the user is not authenticated, only records with `is_public=True` would be returned.
     * The output is ordered ascending by field `start_date`
     * @returns DashboardBroadcastMessage
     * @throws ApiError
     */
    public static fieldServiceBroadcastMessagesList(): CancelablePromise<Array<DashboardBroadcastMessage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/broadcast-messages/',
        });
    }

    /**
     * To handle CRUD operations on model LocationConfiguration
     * @returns DashboardLocationConfiguration
     * @throws ApiError
     */
    public static fieldServiceLocationConfigurationRetrieve(): CancelablePromise<DashboardLocationConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/location-configuration',
        });
    }

    /**
     * To handle CRUD operations on model LocationConfiguration
     * @param requestBody
     * @returns DashboardLocationConfiguration
     * @throws ApiError
     */
    public static fieldServiceLocationConfigurationPartialUpdate(
        requestBody?: PatchedDashboardLocationConfigurationRequest,
    ): CancelablePromise<DashboardLocationConfiguration> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/location-configuration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param orderBy Ordering
     *
     * * `id` - Id
     * * `-id` - Id (descending)
     * * `country` - Country
     * * `-country` - Country (descending)
     * * `city` - City
     * * `-city` - City (descending)
     * * `street` - Street
     * * `-street` - Street (descending)
     * * `postcode` - Postcode
     * * `-postcode` - Postcode (descending)
     * * `code` - Code
     * * `-code` - Code (descending)
     * * `additional_information` - Additional information
     * * `-additional_information` - Additional information (descending)
     * * `status` - Status
     * * `-status` - Status (descending)
     * * `suggested_by` - Suggested by
     * * `-suggested_by` - Suggested by (descending)
     * * `allow_gps_overwrite` - Allow gps overwrite
     * * `-allow_gps_overwrite` - Allow gps overwrite (descending)
     * * `created` - Created
     * * `-created` - Created (descending)
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param roleId
     * Multiple choices are represented in the query string by reusing the same key with different values
     * (e.g. ‘’?status=Regular&status=Admin’’).
     *
     * @param status
     * Multiple choices are represented in the query string by reusing the same key with different values
     * (e.g. ‘’?status=Regular&status=Admin’’).
     *
     * valid_values=['approved', 'pending', 'rejected', 'deactivated']
     *
     * * `approved` - Approved
     * * `pending` - Pending
     * * `rejected` - Rejected
     * * `deactivated` - Deactivated
     * @param text Searches fields street, additional_information, postcode, city, code
     * @returns PaginatedDashboardLocationList
     * @throws ApiError
     */
    public static fieldServiceLocationsList(
        id?: number,
        orderBy?: Array<'-additional_information' | '-allow_gps_overwrite' | '-city' | '-code' | '-country' | '-created' | '-id' | '-postcode' | '-status' | '-street' | '-suggested_by' | 'additional_information' | 'allow_gps_overwrite' | 'city' | 'code' | 'country' | 'created' | 'id' | 'postcode' | 'status' | 'street' | 'suggested_by'>,
        page?: number,
        pageSize?: number,
        roleId?: Array<number>,
        status?: Array<'approved' | 'deactivated' | 'pending' | 'rejected'>,
        text?: string,
    ): CancelablePromise<PaginatedDashboardLocationList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/locations/',
            query: {
                'id': id,
                'order_by': orderBy,
                'page': page,
                'page_size': pageSize,
                'role_id': roleId,
                'status': status,
                'text': text,
            },
        });
    }

    /**
     * @param requestBody
     * @returns DashboardLocation
     * @throws ApiError
     */
    public static fieldServiceLocationsCreate(
        requestBody: DashboardLocationRequest,
    ): CancelablePromise<DashboardLocation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/field-service/locations/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this location.
     * @returns DashboardLocation
     * @throws ApiError
     */
    public static fieldServiceLocationsRetrieve(
        id: number,
    ): CancelablePromise<DashboardLocation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/locations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this location.
     * @param requestBody
     * @returns DashboardLocation
     * @throws ApiError
     */
    public static fieldServiceLocationsUpdate(
        id: number,
        requestBody: DashboardLocationRequest,
    ): CancelablePromise<DashboardLocation> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/field-service/locations/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this location.
     * @param requestBody
     * @returns DashboardLocation
     * @throws ApiError
     */
    public static fieldServiceLocationsPartialUpdate(
        id: number,
        requestBody?: PatchedDashboardLocationRequest,
    ): CancelablePromise<DashboardLocation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/locations/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this location.
     * @returns DashboardLocation
     * @throws ApiError
     */
    public static fieldServiceLocationsApprovePartialUpdate(
        id: number,
    ): CancelablePromise<DashboardLocation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/locations/{id}/approve/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this location.
     * @returns DashboardLocation
     * @throws ApiError
     */
    public static fieldServiceLocationsDeactivatePartialUpdate(
        id: number,
    ): CancelablePromise<DashboardLocation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/locations/{id}/deactivate/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this location.
     * @returns DashboardLocation
     * @throws ApiError
     */
    public static fieldServiceLocationsRejectPartialUpdate(
        id: number,
    ): CancelablePromise<DashboardLocation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/locations/{id}/reject/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * To handle CRUD operations on model SessionConfiguration
     * @returns DashboardSessionConfiguration
     * @throws ApiError
     */
    public static fieldServiceSessionConfigurationRetrieve(): CancelablePromise<DashboardSessionConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/session-configuration',
        });
    }

    /**
     * To handle CRUD operations on model SessionConfiguration
     * @param requestBody
     * @returns DashboardSessionConfiguration
     * @throws ApiError
     */
    public static fieldServiceSessionConfigurationPartialUpdate(
        requestBody?: PatchedDashboardSessionConfigurationRequest,
    ): CancelablePromise<DashboardSessionConfiguration> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/session-configuration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns DashboardSessionResult
     * @throws ApiError
     */
    public static fieldServiceSessionResultsCreate(
        requestBody: DashboardSessionResultRequest,
    ): CancelablePromise<DashboardSessionResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/field-service/session-results/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param status
     * Multiple choices are represented in the query string by reusing the same key with different values
     * (e.g. ‘’?status=Regular&status=Admin’’).
     *
     * valid_values=['ended', 'running', 'overdue', 'canceled']
     *
     * * `ended` - Ended
     * * `running` - Running
     * * `overdue` - Overdue
     * * `canceled` - Canceled
     * @returns PaginatedDashboardSessionList
     * @throws ApiError
     */
    public static fieldServiceSessionsList(
        page?: number,
        pageSize?: number,
        status?: Array<'canceled' | 'ended' | 'overdue' | 'running'>,
    ): CancelablePromise<PaginatedDashboardSessionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/sessions/',
            query: {
                'page': page,
                'page_size': pageSize,
                'status': status,
            },
        });
    }

    /**
     * @param requestBody
     * @returns DashboardSession
     * @throws ApiError
     */
    public static fieldServiceSessionsCreate(
        requestBody: DashboardSessionRequest,
    ): CancelablePromise<DashboardSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/field-service/sessions/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this session.
     * @returns DashboardSession
     * @throws ApiError
     */
    public static fieldServiceSessionsRetrieve(
        id: number,
    ): CancelablePromise<DashboardSession> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/sessions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this session.
     * @param requestBody
     * @returns DashboardSessionUpdate
     * @throws ApiError
     */
    public static fieldServiceSessionsUpdate(
        id: number,
        requestBody?: DashboardSessionUpdateRequest,
    ): CancelablePromise<DashboardSessionUpdate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/field-service/sessions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this session.
     * @param requestBody
     * @returns DashboardSessionUpdate
     * @throws ApiError
     */
    public static fieldServiceSessionsPartialUpdate(
        id: number,
        requestBody?: PatchedDashboardSessionUpdateRequest,
    ): CancelablePromise<DashboardSessionUpdate> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/sessions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param isActive
     * @param name
     * @param orderBy Ordering
     *
     * * `id` - Id
     * * `-id` - Id (descending)
     * * `name` - Name
     * * `-name` - Name (descending)
     * * `is_active` - Is active
     * * `-is_active` - Is active (descending)
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedDashboardWorkAssignmentList
     * @throws ApiError
     */
    public static fieldServiceWorkAssignmentsList(
        isActive?: boolean,
        name?: string,
        orderBy?: Array<'-id' | '-is_active' | '-name' | 'id' | 'is_active' | 'name'>,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedDashboardWorkAssignmentList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/work-assignments/',
            query: {
                'is_active': isActive,
                'name': name,
                'order_by': orderBy,
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @param requestBody
     * @returns DashboardWorkAssignment
     * @throws ApiError
     */
    public static fieldServiceWorkAssignmentsCreate(
        requestBody: DashboardWorkAssignmentRequest,
    ): CancelablePromise<DashboardWorkAssignment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/field-service/work-assignments/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this work assignment.
     * @returns DashboardWorkAssignment
     * @throws ApiError
     */
    public static fieldServiceWorkAssignmentsRetrieve(
        id: number,
    ): CancelablePromise<DashboardWorkAssignment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/work-assignments/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this work assignment.
     * @param requestBody
     * @returns DashboardWorkAssignment
     * @throws ApiError
     */
    public static fieldServiceWorkAssignmentsUpdate(
        id: number,
        requestBody: DashboardWorkAssignmentRequest,
    ): CancelablePromise<DashboardWorkAssignment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/field-service/work-assignments/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this work assignment.
     * @param requestBody
     * @returns DashboardWorkAssignment
     * @throws ApiError
     */
    public static fieldServiceWorkAssignmentsPartialUpdate(
        id: number,
        requestBody?: PatchedDashboardWorkAssignmentRequest,
    ): CancelablePromise<DashboardWorkAssignment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/work-assignments/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this work assignment.
     * @returns void
     * @throws ApiError
     */
    public static fieldServiceWorkAssignmentsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/field-service/work-assignments/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * To handle CRUD operations on model WorkerConfiguration
     * @returns DashboardWorkerConfiguration
     * @throws ApiError
     */
    public static fieldServiceWorkerConfigurationRetrieve(): CancelablePromise<DashboardWorkerConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/worker-configuration',
        });
    }

    /**
     * To handle CRUD operations on model WorkerConfiguration
     * @param requestBody
     * @returns DashboardWorkerConfiguration
     * @throws ApiError
     */
    public static fieldServiceWorkerConfigurationPartialUpdate(
        requestBody?: PatchedDashboardWorkerConfigurationRequest,
    ): CancelablePromise<DashboardWorkerConfiguration> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/worker-configuration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedDashboardWorkerRoleList
     * @throws ApiError
     */
    public static fieldServiceWorkerRolesList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedDashboardWorkerRoleList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/worker-roles/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @param requestBody
     * @returns DashboardWorkerRole
     * @throws ApiError
     */
    public static fieldServiceWorkerRolesCreate(
        requestBody: DashboardWorkerRoleRequest,
    ): CancelablePromise<DashboardWorkerRole> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/field-service/worker-roles/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this worker role.
     * @returns DashboardWorkerRole
     * @throws ApiError
     */
    public static fieldServiceWorkerRolesRetrieve(
        id: number,
    ): CancelablePromise<DashboardWorkerRole> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/worker-roles/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this worker role.
     * @param requestBody
     * @returns DashboardWorkerRole
     * @throws ApiError
     */
    public static fieldServiceWorkerRolesUpdate(
        id: number,
        requestBody: DashboardWorkerRoleRequest,
    ): CancelablePromise<DashboardWorkerRole> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/field-service/worker-roles/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this worker role.
     * @param requestBody
     * @returns DashboardWorkerRole
     * @throws ApiError
     */
    public static fieldServiceWorkerRolesPartialUpdate(
        id: number,
        requestBody?: PatchedDashboardWorkerRoleRequest,
    ): CancelablePromise<DashboardWorkerRole> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/worker-roles/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this worker role.
     * @returns void
     * @throws ApiError
     */
    public static fieldServiceWorkerRolesDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/field-service/worker-roles/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param orderBy Ordering
     *
     * * `id` - Id
     * * `-id` - Id (descending)
     * * `first_name` - First name
     * * `-first_name` - First name (descending)
     * * `last_name` - Last name
     * * `-last_name` - Last name (descending)
     * * `email` - Email
     * * `-email` - Email (descending)
     * * `mobile_number` - Mobile number
     * * `-mobile_number` - Mobile number (descending)
     * * `company` - Company
     * * `-company` - Company (descending)
     * * `status` - Status
     * * `-status` - Status (descending)
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param roleId
     * Multiple choices are represented in the query string by reusing the same key with different values
     * (e.g. ‘’?status=Regular&status=Admin’’).
     *
     * @param status
     * Multiple choices are represented in the query string by reusing the same key with different values
     * (e.g. ‘’?status=Regular&status=Admin’’).
     *
     * valid_values=['approved', 'rejected', 'deactivated', 'pending']
     *
     * * `approved` - Approved
     * * `rejected` - Rejected
     * * `deactivated` - Deactivated
     * * `pending` - Pending
     * @param text Searches fields first_name, last_name, email, company
     * @returns PaginatedDashboardWorkerList
     * @throws ApiError
     */
    public static fieldServiceWorkersList(
        id?: number,
        orderBy?: Array<'-company' | '-email' | '-first_name' | '-id' | '-last_name' | '-mobile_number' | '-status' | 'company' | 'email' | 'first_name' | 'id' | 'last_name' | 'mobile_number' | 'status'>,
        page?: number,
        pageSize?: number,
        roleId?: Array<number>,
        status?: Array<'approved' | 'deactivated' | 'pending' | 'rejected'>,
        text?: string,
    ): CancelablePromise<PaginatedDashboardWorkerList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/workers/',
            query: {
                'id': id,
                'order_by': orderBy,
                'page': page,
                'page_size': pageSize,
                'role_id': roleId,
                'status': status,
                'text': text,
            },
        });
    }

    /**
     * @param requestBody
     * @returns DashboardWorker
     * @throws ApiError
     */
    public static fieldServiceWorkersCreate(
        requestBody: DashboardWorkerRequest,
    ): CancelablePromise<DashboardWorker> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/field-service/workers/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this worker.
     * @returns DashboardWorker
     * @throws ApiError
     */
    public static fieldServiceWorkersRetrieve(
        id: number,
    ): CancelablePromise<DashboardWorker> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/field-service/workers/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this worker.
     * @param requestBody
     * @returns DashboardWorker
     * @throws ApiError
     */
    public static fieldServiceWorkersUpdate(
        id: number,
        requestBody: DashboardWorkerRequest,
    ): CancelablePromise<DashboardWorker> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/field-service/workers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this worker.
     * @param requestBody
     * @returns DashboardWorker
     * @throws ApiError
     */
    public static fieldServiceWorkersPartialUpdate(
        id: number,
        requestBody?: PatchedDashboardWorkerRequest,
    ): CancelablePromise<DashboardWorker> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/workers/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this worker.
     * @returns DashboardWorker
     * @throws ApiError
     */
    public static fieldServiceWorkersApprovePartialUpdate(
        id: number,
    ): CancelablePromise<DashboardWorker> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/workers/{id}/approve/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this worker.
     * @returns DashboardWorker
     * @throws ApiError
     */
    public static fieldServiceWorkersDeactivatePartialUpdate(
        id: number,
    ): CancelablePromise<DashboardWorker> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/workers/{id}/deactivate/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this worker.
     * @returns DashboardWorker
     * @throws ApiError
     */
    public static fieldServiceWorkersRejectPartialUpdate(
        id: number,
    ): CancelablePromise<DashboardWorker> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/field-service/workers/{id}/reject/',
            path: {
                'id': id,
            },
        });
    }

}
