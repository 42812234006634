<template>
  <div>
    <VCard
      class="mb-6"
      title="Welcome to the Conntac Dashboard"
    >
      <VCardText>We are at your service.</VCardText>
    </VCard>
  </div>
</template>
