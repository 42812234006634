/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import { OpenAPI } from '@/api/core/OpenAPI'
import gettext from '@/plugins/gettext'
import App from '@/App.vue'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import '@fontsource/public-sans'
import Markdown from 'vue3-markdown-it'
import router from '@/router'
import '@core/scss/template/index.scss'
import { VueMasonryPlugin } from 'vue-masonry'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import { createApp, markRaw } from 'vue'
import * as Sentry from '@sentry/vue'
import { HttpClient as HttpClientIntegration } from '@sentry/integrations'

declare global {
  interface Window {
    FIELD_SERVICE_API_URL: string
    SENTRY_DSN?: string
    SENTRY_ENVIRONMENT?: string
  }
}

OpenAPI.BASE = window.FIELD_SERVICE_API_URL

// Create vue app
const app = createApp(App)

// Setup sentry
const release = import.meta.env.VITE_SENTRY_RELEASE
const dsn = window.SENTRY_DSN
const environment = window.SENTRY_ENVIRONMENT

if (dsn) {
  Sentry.init({
    app,
    dsn,
    release,
    environment,
    integrations: [
      new HttpClientIntegration({
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = markRaw(router)
})

// Use plugins
app.use(gettext)
app.use(VueMasonryPlugin)
app.use(vuetify)
app.use(pinia)
app.use(Markdown)
app.use(router)
app.use(layoutsPlugin)

// Mount vue app
app.mount('#app')
