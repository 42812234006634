/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateJwtTokenViaOauthRequest } from '../models/CreateJwtTokenViaOauthRequest';
import type { CustomizedTokenObtainPairRequest } from '../models/CustomizedTokenObtainPairRequest';
import type { CustomizedTokenObtainPairSwaggerResponse } from '../models/CustomizedTokenObtainPairSwaggerResponse';
import type { DashboardInvitation } from '../models/DashboardInvitation';
import type { DashboardInvitationRequest } from '../models/DashboardInvitationRequest';
import type { DashboardOauthConfiguration } from '../models/DashboardOauthConfiguration';
import type { DashboardOauthConfigurationRequest } from '../models/DashboardOauthConfigurationRequest';
import type { DashboardUserConfiguration } from '../models/DashboardUserConfiguration';
import type { PaginatedDashboardGroupList } from '../models/PaginatedDashboardGroupList';
import type { PaginatedDashboardInvitationList } from '../models/PaginatedDashboardInvitationList';
import type { PaginatedUserList } from '../models/PaginatedUserList';
import type { PasswordResetConfirmRequest } from '../models/PasswordResetConfirmRequest';
import type { PasswordResetRequestRequest } from '../models/PasswordResetRequestRequest';
import type { PatchedDashboardOauthConfigurationRequest } from '../models/PatchedDashboardOauthConfigurationRequest';
import type { PatchedDashboardUserConfigurationRequest } from '../models/PatchedDashboardUserConfigurationRequest';
import type { PatchedEmailResetRequestRequest } from '../models/PatchedEmailResetRequestRequest';
import type { PatchedInvitationAcceptRequest } from '../models/PatchedInvitationAcceptRequest';
import type { PatchedProfileRequest } from '../models/PatchedProfileRequest';
import type { PatchedUserRequest } from '../models/PatchedUserRequest';
import type { Permission } from '../models/Permission';
import type { Profile } from '../models/Profile';
import type { TokenRefresh } from '../models/TokenRefresh';
import type { TokenRefreshRequest } from '../models/TokenRefreshRequest';
import type { TokenVerifyRequest } from '../models/TokenVerifyRequest';
import type { User } from '../models/User';
import type { UserRequest } from '../models/UserRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserManagementService {

    /**
     * User confirms their 'change-email' operation
     * (by clicking on the link which is sent to their new email address)
     * @param token
     * @param uidb64
     * @returns any No response body
     * @throws ApiError
     */
    public static userManagementChangeEmailConfirmRetrieve(
        token: string,
        uidb64: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/change-email-confirm/{uidb64}/{token}/',
            path: {
                'token': token,
                'uidb64': uidb64,
            },
        });
    }

    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedDashboardGroupList
     * @throws ApiError
     */
    public static userManagementGroupsList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedDashboardGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/groups/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedDashboardInvitationList
     * @throws ApiError
     */
    public static userManagementInvitationsList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedDashboardInvitationList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/invitations/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @param requestBody
     * @returns DashboardInvitation
     * @throws ApiError
     */
    public static userManagementInvitationsCreate(
        requestBody: DashboardInvitationRequest,
    ): CancelablePromise<DashboardInvitation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/invitations/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this invitation.
     * @returns void
     * @throws ApiError
     */
    public static userManagementInvitationsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user-management/invitations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this invitation.
     * @returns any No response body
     * @throws ApiError
     */
    public static userManagementInvitationsResendPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/invitations/{id}/resend/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param token
     * @param uidb64
     * @param requestBody
     * @returns DashboardInvitation
     * @throws ApiError
     */
    public static userManagementInvitationsAcceptPartialUpdate(
        token: string,
        uidb64: string,
        requestBody?: PatchedInvitationAcceptRequest,
    ): CancelablePromise<DashboardInvitation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/invitations/accept/{uidb64}/{token}/',
            path: {
                'token': token,
                'uidb64': uidb64,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Uses service functions to perform actions:
     * - create
     * - update
     * - destroy
     * @returns DashboardOauthConfiguration
     * @throws ApiError
     */
    public static userManagementOauthConfigurationsList(): CancelablePromise<Array<DashboardOauthConfiguration>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/oauth-configurations/',
        });
    }

    /**
     * Uses service functions to perform actions:
     * - create
     * - update
     * - destroy
     * @param requestBody
     * @returns DashboardOauthConfiguration
     * @throws ApiError
     */
    public static userManagementOauthConfigurationsCreate(
        requestBody: DashboardOauthConfigurationRequest,
    ): CancelablePromise<DashboardOauthConfiguration> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/oauth-configurations/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Uses service functions to perform actions:
     * - create
     * - update
     * - destroy
     * @param id A unique integer value identifying this oauth configuration.
     * @returns DashboardOauthConfiguration
     * @throws ApiError
     */
    public static userManagementOauthConfigurationsRetrieve(
        id: number,
    ): CancelablePromise<DashboardOauthConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/oauth-configurations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Uses service functions to perform actions:
     * - create
     * - update
     * - destroy
     * @param id A unique integer value identifying this oauth configuration.
     * @param requestBody
     * @returns DashboardOauthConfiguration
     * @throws ApiError
     */
    public static userManagementOauthConfigurationsUpdate(
        id: number,
        requestBody: DashboardOauthConfigurationRequest,
    ): CancelablePromise<DashboardOauthConfiguration> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user-management/oauth-configurations/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Uses service functions to perform actions:
     * - create
     * - update
     * - destroy
     * @param id A unique integer value identifying this oauth configuration.
     * @param requestBody
     * @returns DashboardOauthConfiguration
     * @throws ApiError
     */
    public static userManagementOauthConfigurationsPartialUpdate(
        id: number,
        requestBody?: PatchedDashboardOauthConfigurationRequest,
    ): CancelablePromise<DashboardOauthConfiguration> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/oauth-configurations/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Uses service functions to perform actions:
     * - create
     * - update
     * - destroy
     * @param id A unique integer value identifying this oauth configuration.
     * @returns void
     * @throws ApiError
     */
    public static userManagementOauthConfigurationsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user-management/oauth-configurations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Exchanges authorization token (obtained from oauth-service)
     * with a token-pair of our backend.
     *
     * This only works for users who already exist in our backend DB.
     * @param requestBody
     * @returns CustomizedTokenObtainPairSwaggerResponse
     * @throws ApiError
     */
    public static userManagementOauthTokenCreate(
        requestBody: CreateJwtTokenViaOauthRequest,
    ): CancelablePromise<CustomizedTokenObtainPairSwaggerResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/oauth/token/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param token
     * @param uidb64
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static userManagementPasswordResetConfirmCreate(
        token: string,
        uidb64: string,
        requestBody: PasswordResetConfirmRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/password-reset-confirm/{uidb64}/{token}/',
            path: {
                'token': token,
                'uidb64': uidb64,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sends an email containing password reset link, to the requested user.
     * If the user does not exist, no email would be sent, but the response status code, would still be 200,
     * this way, backend does not reveal if the requested user does or does not exist.
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static userManagementPasswordResetRequestCreate(
        requestBody: PasswordResetRequestRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/password-reset-request/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Lists all available permissions of the deployment
     * @returns Permission
     * @throws ApiError
     */
    public static userManagementPermissionsList(): CancelablePromise<Array<Permission>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/permissions/',
        });
    }

    /**
     * Shows profile of the currently authenticated user
     * @returns Profile
     * @throws ApiError
     */
    public static userManagementProfileRetrieve(): CancelablePromise<Profile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/profile/',
        });
    }

    /**
     * Currently authenticated user can update their profile
     * @param requestBody
     * @returns Profile
     * @throws ApiError
     */
    public static userManagementProfilePartialUpdate(
        requestBody?: PatchedProfileRequest,
    ): CancelablePromise<Profile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/profile/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * An authenticated user send a request to change their email address.
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static userManagementProfileChangeEmailRequestPartialUpdate(
        requestBody?: PatchedEmailResetRequestRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/profile/change-email-request/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Is used for first time authentication,
     * by providing user credentials (email & password).
     *
     * After successfully obtaining tokens from this endpoint, client can refresh it's token
     * using refresh-endpoint: `/token/refresh/`
     * @param requestBody
     * @returns CustomizedTokenObtainPairSwaggerResponse
     * @throws ApiError
     */
    public static userManagementTokenCreate(
        requestBody: CustomizedTokenObtainPairRequest,
    ): CancelablePromise<CustomizedTokenObtainPairSwaggerResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/token/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @param requestBody
     * @returns TokenRefresh
     * @throws ApiError
     */
    public static userManagementTokenRefreshCreate(
        requestBody: TokenRefreshRequest,
    ): CancelablePromise<TokenRefresh> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/token/refresh/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no
     * information about a token's fitness for a particular use.
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static userManagementTokenVerifyCreate(
        requestBody: TokenVerifyRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/token/verify/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * To handle CRUD operations on model UserConfiguration
     * @returns DashboardUserConfiguration
     * @throws ApiError
     */
    public static userManagementUserConfigurationRetrieve(): CancelablePromise<DashboardUserConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/user-configuration',
        });
    }

    /**
     * To handle CRUD operations on model UserConfiguration
     * @param requestBody
     * @returns DashboardUserConfiguration
     * @throws ApiError
     */
    public static userManagementUserConfigurationPartialUpdate(
        requestBody?: PatchedDashboardUserConfigurationRequest,
    ): CancelablePromise<DashboardUserConfiguration> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/user-configuration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param groupId
     * Multiple choices are represented in the query string by reusing the same key with different values
     * (e.g. ‘’?status=Regular&status=Admin’’).
     *
     * @param groups
     * Multiple choices are represented in the query string by reusing the same key with different values
     * (e.g. ‘’?status=Regular&status=Admin’’).
     *
     * @param id
     * @param isActive
     * @param orderBy Ordering
     *
     * * `id` - Id
     * * `-id` - Id (descending)
     * * `first_name` - First name
     * * `-first_name` - First name (descending)
     * * `last_name` - Last name
     * * `-last_name` - Last name (descending)
     * * `email` - Email
     * * `-email` - Email (descending)
     * * `is_active` - Is active
     * * `-is_active` - Is active (descending)
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param text Searches fields first_name, last_name, email
     * @returns PaginatedUserList
     * @throws ApiError
     */
    public static userManagementUsersList(
        groupId?: Array<number>,
        groups?: Array<string>,
        id?: number,
        isActive?: boolean,
        orderBy?: Array<'-email' | '-first_name' | '-id' | '-is_active' | '-last_name' | 'email' | 'first_name' | 'id' | 'is_active' | 'last_name'>,
        page?: number,
        pageSize?: number,
        text?: string,
    ): CancelablePromise<PaginatedUserList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/users/',
            query: {
                'group_id': groupId,
                'groups': groups,
                'id': id,
                'is_active': isActive,
                'order_by': orderBy,
                'page': page,
                'page_size': pageSize,
                'text': text,
            },
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static userManagementUsersCreate(
        requestBody: UserRequest,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user-management/users/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this user.
     * @returns User
     * @throws ApiError
     */
    public static userManagementUsersRetrieve(
        id: number,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user-management/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this user.
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static userManagementUsersUpdate(
        id: number,
        requestBody: UserRequest,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user-management/users/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this user.
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static userManagementUsersPartialUpdate(
        id: number,
        requestBody?: PatchedUserRequest,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/users/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Invokes 'change email request' procedure for the requested user
     * @param id A unique integer value identifying this user.
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static userManagementUsersChangeEmailRequestPartialUpdate(
        id: number,
        requestBody?: PatchedEmailResetRequestRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/users/{id}/change-email-request/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this user.
     * @returns User
     * @throws ApiError
     */
    public static userManagementUsersDeactivatePartialUpdate(
        id: number,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/users/{id}/deactivate/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this user.
     * @returns any No response body
     * @throws ApiError
     */
    public static userManagementUsersPasswordResetRequestPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user-management/users/{id}/password-reset-request/',
            path: {
                'id': id,
            },
        });
    }

}
