/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigSwaggerResponse } from '../models/ConfigSwaggerResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigService {

    /**
     * @returns ConfigSwaggerResponse
     * @throws ApiError
     */
    public static configRetrieve(): CancelablePromise<ConfigSwaggerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/config/',
        });
    }

}
